
export function setRequestContracts(state, request) {
    state.requestContracts = request
}

export function setIsLoadingContracts(state, bool) {
    state.isLoadingContracts = bool
}

export function setContracts(state, contracts) {    
    state.contracts = contracts
}

export function setDataHousingNewContract(state, data) {    
    const { fullname, owner, housingid } = data
    state.newContract.nameHouse = fullname
    state.newContract.owners = owner
    state.newContract.idHousing = housingid

}

export function setDataDurationNewContract(state, data) {    
    const { duration, dateIn, dateOut } = data
    state.newContract.duration = duration
    state.newContract.dateIn = dateIn
    state.newContract.dateOut = dateOut
}
// Para creacion de contrato
export function setDatesNewContract(state, data) {    
    const { dateIn, dateOut } = data    
    state.newContract.dateIn = dateIn
    state.newContract.dateOut = dateOut
}

export function resetDataHousingNewContract(state) {        
    state.newContract.nameHouse = ''
    state.newContract.owners = []
    state.newContract.hotel = null
    state.newContract.numberHouse = ''
    state.newContract.idHousing = ''
    state.newContract.ownerSelected = ''    
}
// Para edción de contrato

export function setDataDurationEditContract(state, data) {    
    const { duration, dateStart, dateEnd } = data
    state.selectedContract.duration = duration
    state.selectedContract.dateStart = dateStart
    state.selectedContract.dateEnd = dateEnd
}
export function setDatesEditContract(state, data) {    
    const { dateStart, dateEnd } = data    
    state.selectedContract.dateStart = dateStart
    state.selectedContract.dateEnd = dateEnd
}


export function resetOwnerSelectedNewContract(state) {        
    state.newContract.ownerSelected = ''    
}

export function setBenefitsNewContract(state, benefits) { 
    state.newContract.benefitsMembership = benefits
}

export function setExtraBenefitsNewContract(state, extraBenefits) { 
    state.newContract.extraBenefitsMembership = extraBenefits
}

export function resetExtraBenefitsNewContract(state) { 
    const extraBenefits = [...state.newContract.extraBenefitsMembership] 
    extraBenefits.forEach( extraBenefit => {
        extraBenefit.dateIn = ''
        extraBenefit.dateOut = ''
        extraBenefit.qty = ''
        extraBenefit.notes = ''
        extraBenefit.isSelected = false
    })
    state.newContract.extraBenefitsMembership = extraBenefits
}


export function setDatesExtraBenefitsNewContract(state, payload) { 
    
    const { dateIn, dateOut } = payload
    const extraBenefits = [...state.newContract.extraBenefitsMembership] 
    extraBenefits.forEach( extraBenefit => {
        extraBenefit.dateIn = dateIn
        extraBenefit.dateOut = dateOut     
    })
    state.newContract.extraBenefitsMembership = extraBenefits
}

export function setDatesNewBenefitsEditContract(state, payload) {
    
    const { dateIn, dateOut } = payload
    const newBenefits = [...state.selectedContract.benefitsNew] 
    newBenefits.forEach( newBenefit => {
        newBenefit.dateIn = dateIn
        newBenefit.dateOut = dateOut
    })
    state.newContract.extraBenefitsMembership = newBenefits
}

export function completeDataExtraBenefitInNewContract(state, payload) { 
    const { benefit, notes, qty, dateIn, dateOut, isSelected } =  payload
    const idx = state.newContract.extraBenefitsMembership.findIndex( b => b.benefit === benefit )
    state.newContract.extraBenefitsMembership[idx].dateIn = dateIn       
    state.newContract.extraBenefitsMembership[idx].dateOut = dateOut        
    state.newContract.extraBenefitsMembership[idx].isSelected = isSelected     
    state.newContract.extraBenefitsMembership[idx].notes = notes
    state.newContract.extraBenefitsMembership[idx].qty = qty  
}

export function resetDataExtraBenefitInNewContract(state, payload) { 
    const { benefit } =  payload
    const idx = state.newContract.extraBenefitsMembership.findIndex( b => b.benefit === benefit )
    // state.newContract.extraBenefitsMembership[idx].dateInit = ''
    // state.newContract.extraBenefitsMembership[idx].dateEnd = ''
    state.newContract.extraBenefitsMembership[idx].isSelected = false     
    state.newContract.extraBenefitsMembership[idx].qty = ''  
}

export function setNewContractRemodelatingDate(state, boolean) {
    state.newContract.canRemodelate = boolean
    if(!boolean){
        state.newContract.remodelingCommitmentDate = null
    }
}

export function setIsTemporalNewContract(state, boolean) {
    state.newContract.isTemporal = boolean         
}
export function setOnSaleNewContract(state, boolean) {
    state.newContract.isResale = boolean 
    // si el false reseteo el input priceResale
    if(!boolean){
        state.newContract.priceResale = 0
    }
}

export function setIsTemporalSelectedContract(state, boolean) {
    state.selectedContract.isTemporal = boolean         
}
export function setOnSaleSelectedContract(state, boolean) {
    state.selectedContract.isResale = boolean
    if(!boolean){
        state.selectedContract.priceResale = 0
    }
}

export function setContractRemodelatingDate(state, boolean) {
    state.selectedContract.canRemodelate = boolean
    if(!boolean){
        state.selectedContract.remodelingCommitmentDate = null
    }
}

export function resetDataInNewContract(state) { 
    state.newContract.benefitsMembership = []       
    state.newContract.extraBenefitsMembership = []        
    state.newContract.canRemodelate = false
    state.newContract.remodelingCommitmentDate = null
    state.newContract.dateOut = ''     
    state.newContract.dateIn = ''        
    state.newContract.duration = 1
    state.newContract.hotel = null
    state.newContract.idHousing = ''
    state.newContract.nameHouse = ''
    state.newContract.notes = ''
    state.newContract.numberHouse = ''
    state.newContract.owners = []
    state.newContract.ownerSelected = ''
    state.newContract.selectedeMembership = ''
    state.newContract.statusSelected = 1
    state.newContract.isTemporal = false
}

export function setContractDocs(state, contract) {    
    state.contractDocs = contract
}

export function addContract(state, contract) {
    state.contracts = [...state.contracts, contract]
}


export function setStatusContracts(state, status) {
    state.contractstatus = status
}

export function setMemberships(state, Memberships) {
    state.memberships = Memberships
}

export function setExtraBenefits (state, extraBenefits) {
    state.extraBenefits = extraBenefits
}

export function setSelectedContract(state, selectedContract) {
    state.selectedContract = selectedContract
}

export function setBenefitsInSelectedContract(state, benefits) {
    state.selectedContract.benefitsMembership = benefits
}

export function setExtraBenefitsInSelectedContract(state, extraBenefits) {
    state.selectedContract.benefitsExtra = extraBenefits
}

export function setNewBenefitsInSelectedContract(state, newBenefits) {
    state.selectedContract.benefitsNew = newBenefits
}

export function setTransactions(state, transactions) {
    state.transactions = transactions
}

export function setDetailTransactions(state, detailTransactions) {
    state.detailTransactions = detailTransactions
}

export function completeDataNewBenefitInSelectedContract(state, payload) {
    const { uuid, qty, dateIn, dateOut, isSelected, notes } =  payload
    const idx = state.selectedContract.benefitsNew.findIndex( b => b.uuid === uuid )
    state.selectedContract.benefitsNew[idx].dateIn = dateIn
    state.selectedContract.benefitsNew[idx].dateOut = dateOut
    state.selectedContract.benefitsNew[idx].isSelected = isSelected
    state.selectedContract.benefitsNew[idx].qty = qty
    state.selectedContract.benefitsNew[idx].notes = notes
    // state.selectedContract.benefitsNew[idx].isNew = isNew || false

}

export function resetDataNewBenefitInSelectedContract(state, payload) {
    const { uuid } =  payload
    const idx = state.selectedContract.benefitsNew.findIndex( b => b.uuid === uuid )
    state.selectedContract.benefitsNew[idx].isSelected = false
    state.selectedContract.benefitsNew[idx].qty = ''
    state.selectedContract.benefitsNew[idx].notes = ''
}

export function resetAllDataNewBenefitInSelectedContract(state) {
    const newBenefits = [...state.selectedContract.benefitsNew] 
    newBenefits.forEach( newBenefit => {
        newBenefit.dateIn = ''
        newBenefit.dateOut = ''
        newBenefit.qty = ''
        newBenefit.notes = ''
        newBenefit.isSelected = false
    })
    state.selectedContract.benefitsNew = newBenefits
}

export function setDataExtraBenefitInSelectedContract(state, payload) {
    const { id, qty, notes } =  payload
    const idx = state.selectedContract.benefitsExtra.findIndex( b => b.id === id )
    state.selectedContract.benefitsExtra[idx].qty = qty       
    state.selectedContract.benefitsExtra[idx].notes = notes  
    state.selectedContract.benefitsExtra[idx].isReseted = false        

}

export function resetExtraBenefitInSelectedContract(state, payload) {
    const { id } =  payload
    const idx = state.selectedContract.benefitsExtra.findIndex( b => b.id === id )
    state.selectedContract.benefitsExtra[idx].isReseted = true        
    state.selectedContract.benefitsExtra[idx].qty = ''        
    state.selectedContract.benefitsExtra[idx].notes = ''     
}

export function setCurrentRemodelation(state, currentRemodelations) {
    state.selectedContract.currentRemodelations = currentRemodelations
}

export function setResaleAgreements(state, resaleAgreements) {
    state.selectedContract.resaleAgreements = resaleAgreements
}

export function setMinDateContract(state, date) {
    state.minDateContractCreate = date
}

export function setContractsRemodelationType(state, remodelationTypes) {
    state.remodelationTypes = remodelationTypes
}

export function setContractPaymentTypes(state, contractPaymentTypes) {
    state.contractPaymentTypes = contractPaymentTypes
}