export default {
    contracts: [],
    filter: {
        queryContracts: '',
    },
    selectedContract: null,
    requestContracts: null,
    contractDocs: null,
    isLoadingContracts: false,
    newContract:{
        benefitsMembership:[],
        extraBenefitsMembership:[],
        canRemodelate: false,
        remodelingCommitmentDate: null,
        dateOut: '',
        dateIn: '',
        duration: '',
        hotel: null,
        idHousing:'',
        nameHouse:'',
        notes:'',
        isResale: false,
        priceResale: 0,
        numberHouse: '',
        owners: [],
        ownerSelected:'',
        paymentType: 1,
        returnType: 1,
        selectedeMembership: null,
        statusSelected: null,
        isTemporal: false,
    },
    transactions:[],
    detailTransactions:[], 
    yearList: null ,
    minDateContractCreate: null,
    remodelationTypes: [],
    contractPaymentTypes: []
}