export default {
    dataOrderPay: null,
    updateDataOrderPay: {
        card: [],
        amount: '',
        order: ''
    },
    dataListDescriQuoted: [],
    dataListDescriQuotedFired: [],
    dataListDescriTerms: [],
    dataListDescriAmenities: [],
    dataListInclucions: [],
    cart: {
        cliente: {
            idUser: '',
            name: '',
            lastname: '',
            lastnameSearch: '',
            applyManualRoomCharge: false,
            email: '',
            customertype: '',
            language: 1,
            customeragent: '',
            phone: '',
            paymentmethod: '',
            paymentmethod2: '',
            paymentamount: '',
            paymentamount2: 0,
            paymentList: [],
            paymentreference: '',
            paymentreference2: '',
            paymentlink: '',
            credit: '0.00',
            resortCredit: '0.00',
            isBenefitCredit:false,
            creditBalance:'0.00',
            resortCreditBalance:'0.00',
            isPackage: false,
            isOwnerBooking:false
        },
        items: [],
        benefits:[],
        currentBenefits:[],
        currentContract:null,
        membershipsDiscount:[],
        contract:[],
        contractId:'',
        benefitId:'',
        selectedBenefitSelectedInOwnerBooking:null,
        subtotal: 0.00,
        totalDiscount: 0.00,
        totalBreakdown: {
            total: 0.00,
            discountApplied: 0
        },
        dataOrder: null,
        epayment:false,
        files:null,
        makeRoomCharge: false,
        numreserva: '',
        room: '',
        partial: 0,
        firstPay: 0.00,
        firstPayTotal: 0.00,
        isPackage: false,
        ownerbookingbenefits: {
            active: false,
            idconfigurationRoom:'',
            nightsContract:false,
            nightsXtra:false,
            hasBothNights:false
        },
        requiredFields: [],
        currencyRate: {
            firstPay: 0.00,
            totalBreakdown: 0.00,
            firstPayTotal: 0.00,
            totalDiscount: 0.00,
            subtotal: 0.00
        }
    },
    quoted: {
        idWelcom: '',
        idFired: '',
        termsId: '',
        idAmenities: '',
        dataListcontent: '',
        dataListcontentFired: '',
        dataListcontentTerms: '',
        dataListcontentAmenities: '',
        isQuotes: false,
        isRoom: false
    },
    userAuthorizeRate: {
        isInvalid: true,
        idUser: null,
        username: '',
        code: '',
        urlAuthorization: ''
    },
    paymentFormat: {
        total: 0,
        id: "",
        reference: '',

    },
    owners: [],
    guests: [],
    canalventa: null,
    puntoventa: null,
    isLoadingProcess: false,
    validQuoted: false,
    needAuthorization: false,
    listBenefitUsed: [],
    listMemberBenefitUsed: [],
    errors: {
        emailCheck: { error: false, message: '' },
        roomCharge: { error: false, message: '' },
        sendInfoOrder: { error: false, message: '' },
        retryPayment: { error: false, message: '' },
        sendItemsOrderCart: { error: false, message: '' },
        cancelOrderCart: { error: false, message: '' },
    },
    currency: null,
    isReloadingProductsInCart: false,
    isLoadingBenefitsDiscountsAssingnment: false
}