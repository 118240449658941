import Vue from 'vue'
import * as moment from 'moment'
import { showAlertMessage } from '@/helpers/helpers'
import { setNameOwnersInContracts } from '@/helpers/fivesClubHelper'

import { fetchHotels } from '@/modules/start/store/actions'
import { fetchPaymentTypes, fetchRetornos, fetchGetRentalPool } from '@/modules/fivesClub/store/catalogs/actions'



export async function getInitialContentContracts(context, required) {
    const payloadHotels = { onlyDingusCode: false, all: false, showInWeb: true }

    try {
        if (required?.statuses) {
            const statuses= await statusContract()
            context.commit('setStatusContracts', statuses)
        }
        if (required?.memberships) {
            const memberships= await fetchMemberships()
            context.commit('fivesClubCatalogs/setMemberships', memberships, { root:true })
        }
        if (required?.hotels) {
            const hotels= await fetchHotels(payloadHotels)
            context.commit('start/setHotels', hotels, { root: true })
        }
        if (required?.paymentTypes) {
            const paymentTypes= await fetchPaymentTypes()
            context.commit('fivesClubCatalogs/setPaymentTypes', paymentTypes, { root:true })
        }
        if (required?.retornos) {
            const retornos= await fetchRetornos()
            context.commit('fivesClubCatalogs/setRetornos', retornos, { root:true })
        }
        if (required?.rentalPool) {
            const rentalPool= await fetchGetRentalPool()
            context.commit('fivesClubCatalogs/setRentalPool', rentalPool, { root:true })
        }

        return true
    } catch (error) {
        console.warn(error);
        return false
    } finally {
        console.log('La petición para obtener los datos iniciales de edición de contratos ha terminado');
    }
}

//#region Fetch

export async function fetchContracts(context, payload) {
    
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getContracts/`,
            params: { ...payload }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        if (payload?.simple) return data
        return status ? setNameOwnersInContracts(data) : []
    } catch (error) {
        console.log(error)
        return payload?.simple ? false : []
    } finally {
        console.log('fetchContracts end')
    }
}

export async function fetchRemodelationsPerContract(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getRemodelationsPerContract/`,
            params: { ...payload }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status ? data : []
    } catch (error) {
        console.log(error)
        return []
    } finally {
        console.log('fetchRemodelationsPerContract end')
    }
}

export async function fetchResaleAgreements(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getContractResaleAgreements/`,
            params: { ...payload }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status ? data : []
    } catch (error) {
        console.log(error)
        return []
    } finally {
        console.log('fetchRemodelationsPerContract end')
    }
}

export async function fetchExcelContracts(context, payload) {
    
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getContracts/`,
            responseType:'blob',
            params: { ...payload }
        })
        const respuesta = response && response.data  
        const fileURL = window.URL.createObjectURL(new Blob([respuesta]))
        const fileLink = document.createElement("a")
        fileLink.href = fileURL
        fileLink.setAttribute("download", 'excel-contracts.xlsx')
        document.body.appendChild(fileLink)
        fileLink.click()   

    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchExcelContracts end')
    }
}

export async function fetchExcelTransactions(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getTransactions/`,             
            responseType:'blob',
            params: { ...payload }
        })
        const respuesta = response && response.data  
        const fileURL = window.URL.createObjectURL(new Blob([respuesta]))
        const fileLink = document.createElement("a")
        fileLink.href = fileURL
        fileLink.setAttribute("download", 'Transactions'+moment().format("YYYY-MM-DD")+'.xlsx')
        document.body.appendChild(fileLink)
        fileLink.click()   

    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchExcelTransactions end')
    }
}

export async function fetchPreviousCanceledContract(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getPreviousCanceledContract/`,
            params: { ...payload }
        })
        const respuesta = response && response.data
        return respuesta?.data

    } catch (error) {
        console.log(error)
        return null
    } finally {
        console.log('fetchPreviousCanceledContract end')
    }
}

export async function fetchBenefitsXtra(context, payload) {
    try {
        const response = await Vue.axios({
            url: `fivesClub/benefitsXtra/?`,
            params: {
                idMembership: payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status ? data : []
    } catch (error) {
        console.log(error)
    } finally {
        console.log('La petición para solicitar los beneficios extra ha terminado')
    }
}

export async function fetchMemberships() {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/membership/`,
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        return status ? data : []
    } catch (error) {
        console.log(error)
    } finally {
        console.log('La petición para solicitar las membresias ha terminado')
    }
}

export async function statusContract() {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getStatusContract/`,
        })
        const respuesta = response && response.data
        const {status, data } = respuesta
        return status ? data : []
    } catch (error) {
        console.log(error)
    } finally {
        console.log('statusContract end')
    }
}

export async function fetchContractBenefits(context, payload) {
    try {
        const response = await Vue.axios({
            url: `fivesClub/getContractBenefits/`,
            params: {
                idContract: payload
            }
        })
        const respuesta = response && response.data
        // console.log( respuesta )
        const { status, data } = respuesta        
        return status ? data[0] : { benefitExtra:[], benefitMembership:[], benefitNews:[]}
    } catch (error) {
        console.log(error)
        return { benefitExtra:[], benefitMembership:[], benefitNews:[]}
    } finally {
        console.log('La petición para solicitar los beneficios del contrato actual ha terminado')
    }
}

export async function fetchContractPaymentTypes(context, payload) {
    try {
        const response = await Vue.axios({
            url: `fivesClub/ListPaymentTypes/?`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta        
        return status ? data : []        
    } catch (error) {
        console.log(error)
    } finally {
        console.log('fetchContractPaymentTypes end')
    }
}

export async function fetchTransactions(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getTransactions/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const {status, data, message } = respuesta

        if( !status ) showAlertMessage('', 'BellIcon', `${message}`, 'warning', 4000, 'bottom-right')

        return status ? data : []
    } catch (error) {
        console.log(error)
        return []
    } finally {
        console.log('fetchTransactions end')
    }
}

export async function fetchTransactionsDetail(context, payload) {
    try {
        const {dateIn, dateEnd,id} = payload
        const response = await Vue.axios({
            url: `/fivesClub/getTransactionsDetail/`,
            params: { 
                ...payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta        
        return status ? data : []
        
    } catch (error) {
        console.log(error)
        return []
    } finally {
        console.log('fetchTransactionsDetail end')
    }
}

export async function fetchInfoOwnerOrHousing(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getInfoOwnerOrHousing/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const {status, data } = respuesta        
        return status ? data : []
    } catch (error) {
        console.log(error)
        return []
    } finally {
        console.log('La petición para solicitar la vivienda/propietario del contrato/vivienda ha terminado')
    }
}

export async function getFilesByContracts(context, payload) {
    try {
        const response = await Vue.axios({            
            url: `/fivesClub/contractdocumentsData/`,
            params: { ...payload }            
        })
        const respuesta = response && response.data
        const { status, data } = respuesta        
        return status ? data : []

    } catch (error) {
        console.log(error)
    } finally {
        console.log('getFilesByContracts end')
    }
}


export async function getFilesByBooking(context, payload) {    
    try {
        const response = await Vue.axios({            
            url: `/fivesClub/bookingdocumentsData/`,
            params: { ...payload }            
        })
        const respuesta = response && response.data
        const { status, data } = respuesta        
        return status ? data : []

    } catch (error) {
        console.log(error)
    } finally {
        console.log('getFilesByBooking end')
    }
}

export async function getDocumentsTypeForContracts(context, payload) {
    try {
        const response = await Vue.axios({            
            url: `/fivesClub/getDocumentstype/`,
            data: payload
        })
        const respuesta = response && response.data
        const { status, data } = respuesta        
        return status ? data : []

    } catch (error) {
        console.log(error)
    } finally {
        console.log('getDocumentsTypeForContracts end')
    }
}

export async function fetchContractsRemodelationType(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getContractsRemodelationType/`,
            data: payload
        })
        const respuesta = response && response.data
        const { status, data } = respuesta

        const info = status ? data : []
        context.commit('setContractsRemodelationType', info)

        return true

    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('fetchContractsRemodelationType end')
    }
}

export async function contractActiveServices(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/getContractServices/`,
            params: {
                ...payload
            }
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        const result = status ? data : []
        return result
    } catch (error) {
        console.log(error)
        const {status, message} = error.response && error.response.data
        if (message) console.warn(message)
        return status
    } finally {
        console.log('fetchOwnerBooking end')
    }
}
//#endregion

//#region Save / Update

export async function saveNewContract(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/getContracts/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
        showAlertMessage('Something went wrong', 'BellIcon', 'There was a problem making the contract, try again later.', 'warning', 4000, 'bottom-right')
        return { status: false, data: [] }
    } finally {
        console.log('La petición para crear un nuevo contrato ha terminado')
    }
}

export async function updateContract(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/getContracts/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
        const errorresponse = error?.response && error?.response?.data
        // showAlertMessage('Something went wrong', 'BellIcon', 'There was a problem updating the contract, please try again later.', 'warning', 4000, 'bottom-right')
        return { status: false, data: [], message: errorresponse?.message || false, showMessage: errorresponse?.showMessage || false }
    } finally {
        console.log('La petición para actualizar el contrato ha terminado')
    }
}

export async function saveDetailTransactions(context, payload) {    
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/detailTransactions/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
        const respuesta = error?.response && error?.response?.data
        return respuesta || {status: false, message: 'Hubo un error al actualizar el registro, por favor intentelo más tarde o contacte a soporte.'}
    } finally {
        console.log('saveDetailTransactions end')
    }
}

export async function updateStatusContract(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/changeStatusContract/`,
            data: payload
        })
        const {status, message} = response && response.data
        if (status) showAlertMessage('Cambio realizado', 'InfoIcon', `Se ha completado la actualización: ${message}`, 'success', 4000, 'bottom-right' )
        return status
    } catch (error) {
        console.log(error)
        const {status, message} = error.response && error.response.data
        if (message) showAlertMessage("Error", "InfoIcon", `Ha ocurrido un error al actualizar el registro: ${message} intentelo más tarde.`, "danger", 4000, "bottom-right")
        return status
    } finally {
        console.log('La petición para actualizar el estado del contrato ha terminado')
    }
}

export async function saveContractPaymentTypes(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/ListPaymentTypes/`,
            data: payload
        })
        const respuesta = response && response.data
        const {status} = respuesta

        return status
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('La petición para añadir tipo de pago del contrato ha terminado')
    }
}

export async function updateContractPaymentTypes(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/ListPaymentTypes/`,
            data: payload
        })
        const respuesta = response && response.data
        const {status} = respuesta

        return status
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('La petición para actualizar tipo de pago del contrato ha terminado')
    }
}


export async function deleteContractPaymentTypes(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'DELETE',
            url: `/fivesClub/ListPaymentTypes/`,
            data: payload
        })
        const respuesta = response && response.data
        const {status} = respuesta

        return status
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('La petición para borrar tipo de pago del contrato ha terminado')
    }
}

export async function saveFileInContract(context, payload) {
    
    const { file } = payload
    try {
        const formData = new FormData()
        formData.append('file', file )
        formData.append('data', JSON.stringify(payload))

        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/contractdocumentsData/`,
            data: formData
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
        return {status: false, message: 'Ha ocurrido un error inesperado, intentelo más tarde'}
    } finally {
        console.log('saveFileInContract end')
    }
}


export async function saveFileIbooking(context, payload) {
    
    const { file } = payload
    try {
        const formData = new FormData()
        formData.append('file', file )
        formData.append('data', JSON.stringify(payload))

        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/bookingdocumentsData/`,
            data: formData
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
        return {status: false, message: 'Ha ocurrido un error inesperado, intentelo más tarde'}
    } finally {
        console.log('saveFileIbooking end')
    }
}

export async function saveRemodelationPerContract(context, payload) {
    try {
        const formData = new FormData()
        formData.append('file', payload.remodelationReport)
        formData.append('data', JSON.stringify(payload))

        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/getRemodelationsPerContract/`,
            data: formData
        })
        const respuesta = response && response.data
        const { status } = respuesta
        return status || false
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('saveRemodelationsPerContract end')
    }
}

export async function updateRemodelationPerContract(context, payload) {
    try {
        const formData = new FormData()
        formData.append('file', payload.remodelationReport)
        formData.append('data', JSON.stringify(payload))

        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/getRemodelationsPerContract/`,
            data: formData
        })
        const respuesta = response && response.data
        const { status } = respuesta
        return status || false
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('updateRemodelationPerContract end')
    }
}

export async function saveResaleAgreements(context, payload) {
    try {
        const formData = new FormData()
        formData.append('file', payload.resaleSupport)
        formData.append('data', JSON.stringify(payload))

        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/getContractResaleAgreements/`,
            data: formData
        })
        const respuesta = response && response.data
        const { status } = respuesta
        return status || false
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('saveResaleAgreements end')
    }
}

export async function updateResaleAgreements(context, payload) {
    try {
        const formData = new FormData()
        formData.append('file', payload.resaleSupport)
        formData.append('data', JSON.stringify(payload))

        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/getContractResaleAgreements/`,
            data: formData
        })
        const respuesta = response && response.data
        const { status } = respuesta
        return status || false
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('updateResaleAgreements end')
    }
}

export async function fetchContractGuaranteedAmount(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/guaranteedPaymentPerContract/`,
            params: {
                ...payload
            }
        })

        const respuesta = response && response.data
        const { status, data } = respuesta

        return data || {guaranteedAmount:0, periodicity:null}

    } catch (error) {
        console.log(error)
        return {guaranteedAmount:0, periodicity:null}
    } finally {
        console.log('fetchGuaranteedPaymentReport end')
    }
}

export async function fetchGuaranteedPaymentReport(context, payload) {
    try {
        const response = await Vue.axios({
            url: `/fivesClub/guaranteedPaymentPerContract/`,
            responseType:'blob',
            params: {
                ...payload
            }
        })

        const respuesta = response && response.data  
        const fileURL = window.URL.createObjectURL(new Blob([respuesta]))
        const fileLink = document.createElement("a")
        fileLink.href = fileURL
        fileLink.setAttribute("download", `guaranteed-payments-${payload.code}.xlsx`)
        document.body.appendChild(fileLink)
        fileLink.click()
        return true

    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('fetchGuaranteedPaymentReport end')
    }
}

export async function saveContractGuaranteedAmount(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'POST',
            url: `/fivesClub/guaranteedPaymentPerContract/`,
            data: payload
        })
        const respuesta = response && response.data
        const { status, data } = respuesta
        if (status) showAlertMessage('Ok', 'InfoIcon', `Se ha guardado el monto anual garantizado`, 'success', 4000, 'bottom-right' )
        return data || false
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('updateContractGuaranteedAmount end')
    }
}

export async function updateContractGuaranteedAmount(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/guaranteedPaymentPerContract/`,
            data: payload
        })
        const respuesta = response && response.data
        const { status } = respuesta
        if (status) showAlertMessage('Ok', 'InfoIcon', `Se ha actualizado el monto anual garantizado`, 'success', 4000, 'bottom-right' )
        return status || false
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('updateContractGuaranteedAmount end')
    }
}
//#endregion

//#region Delete

export async function deleteFileInOwnerBooking(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'DELETE',
            url: `/fivesClub/bookingdocumentsData/`,            
            params: { ...payload }

        })
        const respuesta = response && response.data
        // console.log(respuesta)
        return respuesta
    } catch (error) {
        console.log(error)
        return {status: false, message: 'Ha ocurrido un error inesperado, intentelo más tarde'}
    } finally {
        console.log('deleteFileInOwnerBooking end')
    }
}

export async function deleteFileInContract(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'DELETE',
            url: `/fivesClub/contractdocumentsData/`,            
            params: { ...payload }

        })
        const respuesta = response && response.data        
        return respuesta
    } catch (error) {
        console.log(error)
        return {status: false, message: 'Ha ocurrido un error inesperado, intentelo más tarde'}
    } finally {
        console.log('deleteFileInContract end')
    }
}


export async function deleteDetailTransactions(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/detailTransactions/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('deleteDetailTransactions end')
    }
}

export async function deleteTransactions(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/getTransactions/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
    } finally {
        console.log('deleteTransactions end')
    }
}

export async function deleteBenefitInContract(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'PUT',
            url: `/fivesClub/updateBenefitContracts/`,
            data: payload
        })
        const respuesta = response && response.data
        return respuesta
    } catch (error) {
        console.log(error)
        return {status: false, message: 'hubo un error al procesar la petición, intentelo más tarde'}
    } finally {
        console.log('La petición para borrar el beneficio extra del contrato ha terminado')
    }
}

export async function deleteRemodelationPerContract(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'DELETE',
            url: `/fivesClub/getRemodelationsPerContract/`,
            data: payload
        })
        const respuesta = response && response.data
        const { status } = respuesta
        return status || false
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('saveRemodelationsPerContract end')
    }
}

export async function deleteResaleAgreements(context, payload) {
    try {
        const response = await Vue.axios({
            method: 'DELETE',
            url: `/fivesClub/getContractResaleAgreements/`,
            data: payload
        })
        const respuesta = response && response.data
        const { status } = respuesta
        return status || false
    } catch (error) {
        console.log(error)
        return false
    } finally {
        console.log('deleteResaleAgreements end')
    }
}

//#endregion
